import { useContext, useState } from 'react';
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from 'react-netlify-forms';
import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ArtistContext } from 'context/ArtistContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDiamondTurnRight,
  faEnvelope,
  faLocationDot,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import './booking.scss';
import Paypal from 'components/paypal/Paypal';
import { NavigationContactInfo } from 'types';

export default function BookingPage() {
  const artist = useContext(ArtistContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const netlify = useNetlifyForm({
    name: 'Booking',

    action: '/booking',
    honeypotName: 'bot-field',
    onSuccess: (response, context) => {
      console.log('Successfully sent form data to Netlify Server');
      console.log('response', response);
      console.log('context', context);
      reset();
    },
  });

  const [invoiceName, setInvoiceName] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceTotal, setInvoiceTotal] = useState('');
  const [invoiceData, setInvoiceData] = useState(false);
  const onSubmit = data => netlify.handleSubmit(null, data);

  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;
  console.log(errors);

  if (!artist) return <div />;
  const { epkLink, navigation } = artist;
  console.log(epkLink && epkLink);
  return (
    <>
      <Nav />
      <NetlifyFormProvider {...netlify}>
        <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
          <Honeypot />
          <section className="container grid mx-auto px-4 md:px-2 w-full md:w-9 lg:w-6">
            <h1 className="text-2xl md:text-4xl xl:text-6xl col-12">
              Book Studio Time
            </h1>

            <div className="col-12 mx-auto flex flex-column">
              {netlify.success && <p>Thanks for contacting us!</p>}
              {netlify.error && (
                <p>
                  Sorry, we could not reach servers. Because it only works on
                  Netlify, our GitHub demo does not provide a response.
                </p>
              )}
              <div className="inner-form-wrapper w-12">
                <div className="field-wrapper mb-5">
                  <span className="p-float-label">
                    <InputText
                      type="text"
                      id="Artist"
                      className="w-full py-4"
                      {...register('Artist', {
                        required: 'Name is required',
                      })}
                    />
                    <label htmlFor="Artist">Band/Artist Name</label>
                  </span>
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>

                <div className="field-wrapper my-5">
                  <span className="p-float-label">
                    <InputText
                      type="email"
                      id="Email"
                      className="w-full py-4"
                      {...register('Email', {
                        required: 'Email is required',
                        pattern: {
                          value: EMAIL_REGEX,
                          message: 'Invalid email address',
                        },
                      })}
                    />
                    <label htmlFor="Email">Email</label>
                  </span>
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>

                <div className="field-wrapper my-5">
                  <span className="p-float-label">
                    <InputMask
                      id="phone"
                      className="w-full py-4"
                      mask="(999) 999-9999"
                      {...register('Phone', {
                        required: 'Phone is required',
                      })}
                    />
                    <label htmlFor="phone">Phone</label>
                  </span>
                </div>

                <div className="field-wrapper my-5">
                  <span className="p-float-label">
                    <InputText
                      type="text"
                      id="Genre"
                      className="w-full py-4"
                      {...register('Name', {
                        required: 'Name is required',
                      })}
                    />
                    <label htmlFor="Genre">
                      Genre/General Description of Music
                    </label>
                  </span>
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>

                <div className="field-wrapper my-5">
                  <span className="p-float-label">
                    <InputText
                      type="text"
                      id="songs"
                      className="w-full"
                      {...register('Name', {
                        required: 'Name is required',
                      })}
                    />
                    <label htmlFor="songs">
                      How many songs/pieces do you want to record?
                    </label>
                  </span>
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>

                <div className="field-wrapper my-5">
                  <span className="p-float-label">
                    <InputText
                      type="text"
                      id="Time"
                      className="w-full"
                      {...register('Name', {
                        required: 'Name is required',
                      })}
                    />
                    <label htmlFor="Time">
                      When are you thinking of scheduling time?
                    </label>
                  </span>
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>

                <div className="field-wrapper my-5">
                  <span className="p-float-label">
                    <InputTextarea
                      id="Project"
                      autoResize
                      className="w-full"
                      rows={5}
                      cols={30}
                      {...register('Name', {
                        required: 'Name is required',
                      })}
                    />
                    <label htmlFor="Project">
                      What else can you tell us about your project?
                    </label>
                  </span>
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>

                <div>
                  <Button
                    type="submit"
                    label="Submit"
                    className="theme-bg-white theme-border-blk border-1 px-4 md:px-4 py-2 theme-button max-w-max block no-underline my-4 font-bold w-full"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mx-auto border-round theme-bg-white dark-section max-h-max">
              <div className="border-1 border-round px-4 theme-dark-text">
                {navigation?.contactInfo.map(
                  (contactItem: NavigationContactInfo) => (
                    <div className="grid w-full">
                      <div className="col-12">
                        {!!contactItem.name && (
                          <h4 className="font-bold text-dark text-4xl mt-4 mb-2">
                            {contactItem.name}
                          </h4>
                        )}
                      </div>
                      <div className="col-12 md:col-6 animate-col-width">
                        {!!contactItem.email && (
                          <p className="flex align-items-center mt-0 mb-2">
                            <FontAwesomeIcon
                              icon={faEnvelope as IconProp}
                              className="fa-fw mr-2"
                            />
                            <span>{contactItem.email}</span>
                          </p>
                        )}
                        {!!contactItem.phone && (
                          <p className="flex align-items-center mt-0 mb-2">
                            <FontAwesomeIcon
                              icon={faPhone as IconProp}
                              className="fa-fw mr-2"
                            />
                            <span>{contactItem.phone}</span>
                          </p>
                        )}
                        {!!contactItem.address && (
                          <div className="flex flex-row align-items-center">
                            <p>
                              <FontAwesomeIcon
                                icon={faLocationDot as IconProp}
                                className="fa-fw mr-2"
                              />
                            </p>
                            <p className="flex flex-column">
                              <span>{contactItem.address}</span>
                              <span>{contactItem.cityStateZip}</span>
                            </p>
                            <a
                              href={contactItem.googleAddressLink}
                              className="text-primary"
                              target="_blank"
                              rel="noreferrer">
                              <FontAwesomeIcon
                                icon={faDiamondTurnRight as IconProp}
                                className="fa-fw ml-2"
                              />
                            </a>
                          </div>
                        )}
                      </div>
                      <div className="col-12 md:col-6 animate-col-width">
                        {!!contactItem.imageOne && (
                          <img
                            src={contactItem.imageOne}
                            alt=""
                            className="block w-12 border-round mb-4"
                          />
                        )}
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          </section>
        </NetlifyFormComponent>
      </NetlifyFormProvider>
      <section className="container grid mx-auto w-full md:w-9 lg:w-6">
        <div className="mx-auto col-12">
          <h1 className="text-2xl md:text-4xl xl:text-6xl mb-4">Pay Invoice</h1>
          <div className="card theme-bg-white p-4 border-round">
            <div className="field-wrapper my-4">
              <span className="p-float-label">
                <InputText
                  type="text"
                  id="InvoiceName"
                  className="w-full"
                  value={invoiceName}
                  onChange={e => setInvoiceName(e.target.value)}
                />
                <label htmlFor="InvoiceName">Name</label>
              </span>
              {/* {!!errors.email && <div>{errors.email.message}</div>} */}
            </div>
            <div className="field-wrapper my-4">
              <span className="p-float-label">
                <InputText
                  type="text"
                  id="InvoiceNumber"
                  className="w-full"
                  value={invoiceNumber}
                  onChange={e => setInvoiceNumber(e.target.value)}
                />
                <label htmlFor="InvoiceNumber">Invoice Number</label>
              </span>
              {/* {errors.email && <div>{errors.email.message}</div>} */}
            </div>
            <div className="field-wrapper my-4">
              <span className="p-float-label">
                <InputText
                  type="text"
                  id="InvoiceTotal"
                  className="w-full"
                  value={invoiceTotal}
                  onChange={e => setInvoiceTotal(e.target.value)}
                />
                <label htmlFor="InvoiceTotal">Invoice Total</label>
              </span>
              {/* {errors.email && <div>{errors.email.message}</div>} */}
            </div>
            <Button
              onClick={() => setInvoiceData(true)}
              className={`${
                invoiceData
                  ? 'hidden'
                  : 'theme-bg-white theme-border-blk border-1 px-4 md:px-4 py-2 theme-button max-w-max no-underline my-4 font-bold w-full'
              }`}>
              Continue
            </Button>
            {invoiceData && invoiceTotal && (
              <Paypal
                invoiceName={invoiceName}
                invoiceNumber={invoiceNumber}
                invoiceTotal={invoiceTotal}
              />
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
