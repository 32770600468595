import { ReactElement, useContext, useState } from 'react';
import { ArtistContext } from 'context/ArtistContext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import useViewport from 'hooks/useViewport';
import './whatsapp.scss';

export default function Mailchimp(): ReactElement {
  const artist = useContext(ArtistContext);
  const { isMobile } = useViewport();
  const [visibleWhatsappModal, setVisibleWhatsappModal] =
    useState<boolean>(false);
  const [whatsAppMessage, setWhatsappMessage] = useState('');
  const { whatsAppFloatContactNumber, isSpanish, displayWhatsAppFloat } =
    artist;
  if (!artist) return <div />;

  return (
    <>
      {!!whatsAppFloatContactNumber && displayWhatsAppFloat && (
        <Button
          icon="pi pi-whatsapp"
          aria-label="Whatsapp"
          className="fixed floating-whatsapp-button"
          style={{
            inset: isMobile ? 'auto auto 90px 25px' : 'auto auto 40px 40px',
            zIndex: 1010,
            borderRadius: '50%',
            backgroundColor: '#25D366',
            border: '2px solid #fff',
            color: '#fff',
            // transform: 'scale(1.25)',
            aspectRatio: 1,
          }}
          onClick={() => setVisibleWhatsappModal(true)}
        />
      )}
      <Dialog
        visible={visibleWhatsappModal}
        onHide={() => setVisibleWhatsappModal(false)}
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        className="w-10 md:w-6 var-surface-invert"
        draggable={false}
        dismissableMask
        header="Whatsapp"
        headerClassName="py-1 px-2">
        <div className="field-wrapper my-4">
          <span className="p-float-label">
            <InputTextarea
              value={whatsAppMessage}
              onChange={e => setWhatsappMessage(e.target.value)}
              rows={5}
              cols={30}
              className="w-full"
              id="whatsapp-input"
            />
            <label htmlFor="whatsapp-input">
              {isSpanish ? 'Su mensaje' : 'Your Message'}
            </label>
          </span>
        </div>
        <a
          href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
            `${whatsAppFloatContactNumber}`,
          )}&text=${whatsAppMessage}`}
          target="_blank"
          style={{
            backgroundColor: '#25D366',
            border: '2px solid #fff',
            color: '#fff',
          }}
          className="w-full no-underline px-4 py-2 mx-auto block text-center"
          onClick={() => setVisibleWhatsappModal(false)}
          rel="noreferrer">
          {isSpanish ? 'Enviar' : 'Send'}
        </a>
      </Dialog>
    </>
  );
}
