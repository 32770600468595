/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import parse from 'html-react-parser';
import './home.scss';
import Slider from 'react-slick';
import { Fade } from 'react-awesome-reveal';
import { ArtistContext } from 'context/ArtistContext';
import { ActMembersType, featureSectionComponentType } from 'types';
import Whatsapp from 'components/whatsapp/Whatsapp';
import EventGridSlider from 'components/eventGridSlider/EventGridSlider';
import { Link } from 'react-router-dom';
import BlogGridSlider from 'components/blogGridSlider/BlogGridSlider';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { Head } from '../../components/Head';
import Loader from '../../components/loader/Loader';
import FeatureAlbum from './FeatureAlbum';
import VideoHomePage from './videos/VideoHomePage';
import Mailchimp from '../../components/mailchimp/Mailchimp';
import VideoHero from './VideoHero';

const GET_HOMELANDING = gql`
  query GET_HOMELANDING {
    homePages {
      id
      themeName
      title
      subtitle
      bandsInTown
      heroImage {
        id
        url
      }
      hasVideoHero
      displaySocialOverlay
      videoLink {
        id
        url
      }
      tourSectionTitle
      talentSectionTitle
      homeStandoutText
      homeStandoutTextCssWrapperName
      heroSectionBackgroundImage {
        id
        url
      }
      parallaxImage {
        id
        url
      }
      parallaxImageTwo {
        id
        url
      }
      headlinePrimary {
        raw
        html
        markdown
        text
      }
      gallery(first: 500) {
        id
        url
      }
      bioSectionTitle
      bioSectionContent {
        raw
        html
        markdown
        text
      }
      bioSectionImage {
        id
        url
      }
      albumMap
      featureSectionComponents {
        featureSectionButtonLink
        featureSectionButtonText
        featureSectionColumnCount
        featureSectionCssClassNameWrapper
        featureSectionHtmlIdWrapper
        featureSectionSubtitle
        featureSectionTitle
        featureSectionContent {
          raw
          html
          markdown
          text
        }
        featureSectionBackgroundImage {
          id
          url
        }
        featureSectionDisplayImage {
          id
          url
        }
      }
      displayEvents
      displayEventsStyleGridOrSlider
      talents {
        name
        instrument
        miniMemberBio
        emailMemberLink
        facebookMemberLink
        fullMemberBio {
          html
        }
        imageMemberAvatar {
          id
          url
        }
        instagramMemberLink
        linkedinMemberLink
        memberBandsInTown
        youtubeMemberLink
        phoneMemberLink
        linkedinMemberLink
        memberImageGallery(first: 500) {
          id
          url
        }
        memberMusicReleaseGallery(first: 500) {
          id
          url
        }
        memberSlug
        miniMemberBio
        phoneMemberLink
        primaryMember
        tikTokMemberLink
        youtubeMemberLink
      }
    }
  }
`;

export default function HomeLandingPage() {
  const { loading, data } = useQuery(GET_HOMELANDING);
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const { mailchimp, displayBlogNav, logoSecondary } = artist;
  if (loading) return <Loader height="60vh" />;
  const homelandingData = data.homePages[1];
  const {
    themeName,
    title,
    subtitle,
    headlinePrimary,
    bandsInTown,
    heroImage,
    gallery,
    heroSectionBackgroundImage,
    videoLink,
    hasVideoHero,
    parallaxImage,
    parallaxImageTwo,
    displaySocialOverlay,
    cssClassHeroNameWrapper,
    cssClassHeroNameContent,
    bioSectionTitle,
    bioSectionContent,
    bioSectionImage,
    albumMap,
    featureSectionComponents,
    displayEvents,
    displayEventsStyleGridOrSlider,
    talents,
    tourSectionTitle,
    talentSectionTitle,
    homeStandoutText,
    homeStandoutTextCssWrapperName,
  } = homelandingData;

  console.log(themeName, talents);

  const finalImages = gallery.map((image: { url: any }) => image.url);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <>
      <Head metaTitle={title} />
      <Nav logoSecondary={logoSecondary} />
      <Whatsapp />
      {hasVideoHero ? (
        <VideoHero
          cssClassNameWrapper={cssClassHeroNameWrapper}
          cssClassNameContent={cssClassHeroNameContent}
          titleVideo={title}
          subtitleVideo={headlinePrimary.html}
          videoLink={videoLink.url}
          displaySocialOverlay={displaySocialOverlay}
          logoSecondary={logoSecondary}
        />
      ) : (
        <div
          className={`hero-bg ${
            cssClassHeroNameWrapper && cssClassHeroNameWrapper
          }`}
          style={{
            backgroundImage:
              heroSectionBackgroundImage &&
              `url(${heroSectionBackgroundImage.url})`,
          }}>
          <section className="container">
            <div className="grid mx-auto align-items-center mt-4 mb-8">
              {!!headlinePrimary?.html && (
                <div className="headlinePrimary text-3xl md:text-5xl xl:text-7xl col-10 md:col-6">
                  <Fade triggerOnce direction="left">
                    {parse(subtitle)}
                  </Fade>
                </div>
              )}
              {!!heroImage && (
                <Fade
                  triggerOnce
                  className="col-10 md:col-6 mx-auto block"
                  direction="right">
                  <img
                    src={heroImage.url}
                    alt="studio"
                    className="w-full mx-auto block"
                    style={{ maxWidth: '500px' }}
                  />
                </Fade>
              )}
            </div>
          </section>
        </div>
      )}
      {!!title && !!mailchimp && (
        <div className="gradient-bkg m-0 p-0">
          <section className="container py-8 flex align-items center jutsify-content-center">
            <div className="flex flex-column align-items-center text-center w-full md:w-6 mx-auto">
              <Fade triggerOnce direction="up" className="w-full">
                <Mailchimp float={false} title={title} subtitle={subtitle} />
              </Fade>
            </div>
          </section>
        </div>
      )}
      {!!parallaxImage && (
        <div className="parallax-container accent">
          <div
            className="image-parallax"
            style={{
              backgroundImage: `url(${parallaxImage.url})`,
            }}
          />
        </div>
      )}
      {!!talents.length && (
        <section className="container my-8">
          <div className="grid px-4">
            <div className="col-12 md:col-4 lg:col-5">
              <h3 className="text-3xl">{talentSectionTitle}</h3>
            </div>
            <div className="col-12 md:col-8 lg:col-7 grid align-items-center justify-content-end">
              {talents.map((talent: ActMembersType) => (
                <Link
                  to={`/artist/${talent.memberSlug}`}
                  className="talent-card col-12 md:col-4 no-underline"
                  key={talent.memberSlug}>
                  <img
                    src={talent.imageMemberAvatar?.url}
                    alt=""
                    className="talent-card-image"
                  />
                  <h4 className="my-0 py-0">{talent.name}</h4>
                </Link>
              ))}
            </div>
          </div>
        </section>
      )}
      {!!bandsInTown && (
        <section className="container my-4 dark-section">
          <h2 className="text-3xl md:text-6xl my-2 text-center">
            {tourSectionTitle && tourSectionTitle}
          </h2>
          {parse(bandsInTown)}
        </section>
      )}
      {!!finalImages.length && (
        <Slider {...settings} className="">
          {finalImages.map(image => (
            <img src={image} alt="" className="w-12 slider-image" key={image} />
          ))}
        </Slider>
      )}
      {!!featureSectionComponents.length && (
        <div>
          {featureSectionComponents.map(
            (featureSectionComponent: featureSectionComponentType) => (
              <section
                className={
                  featureSectionComponent.featureSectionCssClassNameWrapper
                }
                id={featureSectionComponent.featureSectionHtmlIdWrapper}
                key={featureSectionComponent.featureSectionHtmlIdWrapper}
                style={{
                  backgroundImage:
                    featureSectionComponent.featureSectionBackgroundImage &&
                    `url(${featureSectionComponent.featureSectionBackgroundImage.url})`,
                }}>
                {featureSectionComponent.featureSectionColumnCount === 1 && (
                  <div>
                    {!!featureSectionComponent.featureSectionDisplayImage && (
                      <img
                        src={
                          featureSectionComponent.featureSectionDisplayImage.url
                        }
                        className="w-full block"
                        style={{ objectFit: 'cover', maxHeight: '340px' }}
                        alt={
                          (featureSectionComponent.featureSectionTitle &&
                            featureSectionComponent.featureSectionTitle) ||
                          ''
                        }
                      />
                    )}
                    {!!featureSectionComponent.featureSectionTitle && (
                      <h2>{featureSectionComponent.featureSectionTitle}</h2>
                    )}
                    {!!featureSectionComponent.featureSectionSubtitle && (
                      <h3>{featureSectionComponent.featureSectionSubtitle}</h3>
                    )}
                    {!!featureSectionComponent.featureSectionSubtitle && (
                      <h3>{featureSectionComponent.featureSectionSubtitle}</h3>
                    )}
                    {!!featureSectionComponent.featureSectionContent && (
                      <div className="feature-section-html body-parsed-text">
                        {parse(
                          featureSectionComponent.featureSectionContent.html,
                        )}
                      </div>
                    )}
                  </div>
                )}
                {featureSectionComponent.featureSectionColumnCount === 2 && (
                  <div className="grid">
                    <div className="col-12 md:col-6">
                      {!!featureSectionComponent.featureSectionDisplayImage && (
                        <img
                          src={
                            featureSectionComponent.featureSectionDisplayImage
                              .url
                          }
                          className="w-full block"
                          style={{ objectFit: 'cover', maxHeight: '340px' }}
                          alt={
                            (featureSectionComponent.featureSectionTitle &&
                              featureSectionComponent.featureSectionTitle) ||
                            ''
                          }
                        />
                      )}
                    </div>
                    <div className="col-12 md:col-6">
                      {!!featureSectionComponent.featureSectionTitle && (
                        <h2>{featureSectionComponent.featureSectionTitle}</h2>
                      )}
                      {!!featureSectionComponent.featureSectionSubtitle && (
                        <h3>
                          {featureSectionComponent.featureSectionSubtitle}
                        </h3>
                      )}
                      {!!featureSectionComponent.featureSectionSubtitle && (
                        <h3>
                          {featureSectionComponent.featureSectionSubtitle}
                        </h3>
                      )}
                      {!!featureSectionComponent.featureSectionContent && (
                        <div className="feature-section-html body-parsed-text">
                          {parse(
                            featureSectionComponent.featureSectionContent.html,
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </section>
            ),
          )}
        </div>
      )}
      {!!bioSectionTitle && (
        <section className="container my-8 dark-section">
          <div className="w-10 md:w-6 mx-auto">
            {!!bioSectionImage && (
              <img
                src={bioSectionImage.url}
                alt={(bioSectionTitle && bioSectionTitle) || ''}
                className="w-full mx-auto"
                style={{ objectFit: 'cover' }}
              />
            )}
            {!!bioSectionTitle && (
              <h2 className="text-center">{bioSectionTitle}</h2>
            )}
            {!!bioSectionContent.html && (
              <div className="body-parsed-text">
                {parse(bioSectionContent.html)}
              </div>
            )}
          </div>
        </section>
      )}
      <FeatureAlbum albumMap={albumMap} />
      <VideoHomePage />
      {!!parallaxImageTwo && (
        <div className="parallax-container accent">
          <div
            className="image-parallax"
            style={{
              backgroundImage: `url(${parallaxImageTwo.url})`,
            }}
          />
        </div>
      )}
      {!!displayBlogNav && (
        <section className="container my-8">
          <h2 className="text-center">Blogs</h2>
          <BlogGridSlider />
        </section>
      )}
      {!!displayEvents && (
        <section className="container my-8">
          <EventGridSlider
            displayEventsStyleGridOrSlider={displayEventsStyleGridOrSlider}
          />
        </section>
      )}
      {!!homeStandoutText && (
        <div
          className={`home-standout-text-wrapper gradient-bkg ${
            homeStandoutTextCssWrapperName && homeStandoutTextCssWrapperName
          }`}>
          <section className="container py-8 flex align-items-center justify-content-center">
            <h3 className="home-standout-text text-center w-10 md:w-6 mx-auto">
              {homeStandoutText}
            </h3>
          </section>
        </div>
      )}
      <Footer logoSecondary={logoSecondary} />
    </>
  );
}
