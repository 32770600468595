import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import './blog.scss';
import { BlogType } from 'types';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/loader/Loader';
import { Head } from '../../components/Head';

const GET_BLOGS = gql`
  query GET_BLOGS {
    blogs {
      id
      blogUrl
      title
      excerpt
      blogCallToActionLink
      blogCallToActionText
      image {
        id
        url
      }
      content {
        raw
        html
        markdown
        text
      }
    }
  }
`;

function Blog() {
  const { blogLink } = useParams();
  const { loading, data } = useQuery(GET_BLOGS);
  if (loading) return <Loader height="60vh" />;
  const { blogs } = data;

  const blog = blogs.find((blog: BlogType) => blog.blogUrl === blogLink);
  const { title, content, image, blogCallToActionText, blogCallToActionLink } =
    blog;
  return (
    <>
      <Head metaTitle={title} />
      <Nav />
      <div className="texture-background overflow-hidden relative">
        <div className="w-full mx-auto text-center my-2 md:mt-0 mb-2">
          <Link
            to="/artists"
            className="text-link uppercase no-underline justify-content-center max-w-max">
            <FontAwesomeIcon
              icon={faArrowLeft as IconProp}
              className="fa-fw mr-2"
            />
            <span>Blogs</span>
          </Link>
        </div>
        <section className="w-10 md:w-8 lg:w-6 mx-auto block my-4 p-4">
          {!!image?.url && (
            <img src={image.url} alt="" className="featured-image" />
          )}
          {!!title && <h1 className="uppercase mb-2 gradient-text">{title}</h1>}
          {!!content.html && (
            <div className="body-parsed-text">{parse(content.html)}</div>
          )}
          {!!blogCallToActionText && (
            <div>
              {blogCallToActionLink.includes('https') ? (
                <a
                  href={blogCallToActionLink}
                  target="_blank"
                  className=" theme-bg-white theme-border-blk border-1 px-4 md:px-8 py-3 theme-button mx-auto max-w-max block no-underline my-4 font-bold w-full"
                  rel="noreferrer">
                  {blogCallToActionText}
                </a>
              ) : (
                <Link
                  to={blogCallToActionLink}
                  className=" theme-bg-white theme-border-blk border-1 px-4 md:px-8 py-3 theme-button mx-auto max-w-max block no-underline my-4 font-bold w-full">
                  {blogCallToActionText}
                </Link>
              )}
            </div>
          )}
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
