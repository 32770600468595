/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useViewport from 'hooks/useViewport';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSpotify,
  faInstagram,
  faFacebook,
  faTiktok,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { NavigationItemType } from 'types';
import { ArtistContext } from '../../context/ArtistContext';
import './nav.scss';

interface NavProps {
  logoSecondary?: { url?: string };
}

export default function Nav(props: NavProps) {
  const { isMobile } = useViewport();
  const [visibleBottom, setVisibleBottom] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  useEffect(() => {
    window.addEventListener('scroll', stickyCallBak);

    return () => {
      window.removeEventListener('scroll', stickyCallBak);
    };
  });

  const stickyCallBak = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 350 ? setIsScrolled(true) : setIsScrolled(false);
  };

  const artist = useContext(ArtistContext);
  if (!artist) return <div />;

  const {
    title,
    logo,
    isSpanish,
    siteCssBodyClass,
    facebookLink,
    tikTokLink,
    instagramLink,
    spotifyLink,
    twitterLink,
    youtubeLink,
    bookingPhoneOne,
    bookingEmailOne,
    bookingNameOne,
    navigation,
    hasSplashPage,
    siteLibraryJson,
  } = artist;

  document.body.classList.add(siteCssBodyClass && siteCssBodyClass);
  if (siteLibraryJson?.siteID) {
    document.body.classList.add(siteLibraryJson?.siteID);
  }

  const { logoSecondary } = props;

  return (
    <>
      {!!navigation?.announcementLink?.link && (
        <div className="announcement-bar w-full text-center py-2">
          <a
            href={navigation?.announcementLink?.link}
            target={!navigation?.sameTab === true ? '' : '_blank'}
            className={`text-link mx-auto text-center inline-block max-w-max uppercase text-xs opacity-50  ${navigation?.announcementLink?.cssClass}`}
            rel="noreferrer">
            {navigation?.announcementLink?.label}
          </a>
        </div>
      )}
      {isMobile ? (
        <>
          <Sidebar
            visible={visibleBottom}
            position="bottom"
            style={{ minHeight: '90vh' }}
            onHide={() => setVisibleBottom(false)}
            closeOnEscape
            dismissable
            className="theme-bg-black header-none"
            showCloseIcon={false}>
            <div className="nav-sidebar-content flex flex-column h-full align-items-center justify-content-center">
              <div className="flex align-items-center justify-content-between w-10 mx-auto">
                <Link
                  to="/"
                  onClick={() => setVisibleBottom(false)}
                  className="no-underline">
                  {!!logo || !!logoSecondary ? (
                    <img
                      src={logoSecondary?.url || logo?.url}
                      alt={title || ''}
                      className="block"
                      style={{
                        objectFit: 'cover',
                        maxWidth: '100px',
                        maxHeight: '50px',
                      }}
                    />
                  ) : (
                    <h1 className="p-0 m-0">{title}</h1>
                  )}
                </Link>
                <Button
                  icon="pi pi-times"
                  className="bg-none mobile-icon-nav-button"
                  onClick={() => setVisibleBottom(false)}
                />
              </div>
              <nav className="p-0 my-0 w-10 mx-auto mb-4">
                <ul className="mobile-nav-items flex flex-column w-full p-0 mx-0">
                  {navigation?.navigationItems.map(
                    (navigationItem: NavigationItemType) => (
                      <li
                        key={Math.random()}
                        className="my-2 flex flex-column align-items-center justify-content-center">
                        {navigationItem.link.includes('http') ? (
                          <a
                            href={navigationItem.link}
                            target={navigationItem.sameTab ? '' : '_blank'}
                            className={`w-12 text-center ${navigationItem.cssClass}`}
                            rel="noreferrer"
                            onClick={() => setVisibleBottom(false)}>
                            {navigationItem.label}
                          </a>
                        ) : (
                          <Link
                            to={navigationItem.link}
                            className={`w-12 text-center ${navigationItem.cssClass}`}
                            onClick={() => setVisibleBottom(false)}>
                            {navigationItem.label}
                          </Link>
                        )}
                      </li>
                    ),
                  )}
                </ul>
              </nav>
              <nav className="w-10 mx-auto mb-4 flex flex-row nav-social-icons justify-content-center">
                {!!instagramLink && (
                  <a
                    href={instagramLink}
                    target="_blank"
                    className="max-w-max mx-2 text-center"
                    rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faInstagram as IconProp}
                      className="fa-fw"
                    />
                  </a>
                )}
                {!!spotifyLink && (
                  <a
                    href={spotifyLink}
                    target="_blank"
                    className="max-w-max mx-2 text-center"
                    rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faSpotify as IconProp}
                      className="fa-fw"
                    />
                  </a>
                )}
                {!!facebookLink && (
                  <a
                    href={facebookLink}
                    target="_blank"
                    className="max-w-max mx-2 text-center"
                    rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faFacebook as IconProp}
                      className="fa-fw"
                    />
                  </a>
                )}
                {!!twitterLink && (
                  <a
                    href={twitterLink}
                    target="_blank"
                    className="max-w-max mx-2 text-center"
                    rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faTwitter as IconProp}
                      className="fa-fw"
                    />
                  </a>
                )}
                {!!youtubeLink && (
                  <a
                    href={youtubeLink}
                    target="_blank"
                    className="max-w-max mx-2 text-center"
                    rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faYoutube as IconProp}
                      className="fa-fw"
                    />
                  </a>
                )}
                {!!tikTokLink && (
                  <a
                    href={tikTokLink}
                    target="_blank"
                    className="max-w-max mx-2 text-center"
                    rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faTiktok as IconProp}
                      className="fa-fw"
                    />
                  </a>
                )}
              </nav>
              <div className="w-10 mx-auto mb-4">
                {!!bookingPhoneOne && (
                  <div className="mt-auto mb-4 px-4">
                    {!!bookingNameOne && (
                      <p className="font-bold text-sm">{bookingNameOne}</p>
                    )}
                    {!!bookingEmailOne && (
                      <p className="flex align-items-center text-sm">
                        <FontAwesomeIcon
                          icon={faEnvelope as IconProp}
                          className="fa-fw mr-2"
                        />
                        <span>{bookingEmailOne}</span>
                      </p>
                    )}
                    {!!bookingPhoneOne && (
                      <p className="flex align-items-center text-sm">
                        <FontAwesomeIcon
                          icon={faPhone as IconProp}
                          className="fa-fw mr-2"
                        />
                        <span>{bookingPhoneOne}</span>
                      </p>
                    )}
                  </div>
                )}
                <Button
                  label={isSpanish ? 'Cerrar' : 'Close'}
                  className="bg-none text-primary mb-4 p-1 w-full"
                  onClick={() => setVisibleBottom(false)}
                />
              </div>
            </div>
          </Sidebar>
          <nav
            className="mobile-nav-wrapper fixed theme-bg-black w-full block theme-box-shadow shadow-direction-up"
            style={{ bottom: 0, zIndex: 1000, left: 0, right: 0 }}>
            <div className="flex align-items-center justify-content-between w-full px-4 py-3">
              <Link
                to={hasSplashPage ? '/entertainment' : '/'}
                className="no-underline uppercase">
                {!!logo || !!logoSecondary ? (
                  <img
                    src={logoSecondary?.url || logo?.url}
                    alt={title || ''}
                    className="block"
                    style={{
                      objectFit: 'cover',
                      maxWidth: '100px',
                      maxHeight: '40px',
                    }}
                  />
                ) : (
                  <h2 className="p-0 m-0">{title}</h2>
                )}
              </Link>
              <Button
                icon="pi pi-bars"
                className="bg-none mobile-icon-nav-button"
                onClick={() => setVisibleBottom(true)}
              />
            </div>
          </nav>
        </>
      ) : (
        <nav
          className={`nav-desktop w-full theme-bg-black sticky px-2 ${
            isScrolled ? 'py-1' : 'py-3'
          }`}
          style={{ top: isScrolled ? '0' : '-225px', zIndex: 10000 }}>
          <div className="container flex justify-content-space-between flex-row align-items-center">
            <Link
              to={hasSplashPage ? '/entertainment' : '/'}
              className="no-underline uppercase">
              {!!logo || !!logoSecondary ? (
                <img
                  src={logoSecondary?.url || logo?.url}
                  alt={title || ''}
                  className={`primary-logo ${isScrolled ? 'scrolled' : ''}`}
                />
              ) : (
                <h1
                  className={`nav-text-title p-0 m-0 ${
                    isScrolled ? 'scrolled' : ''
                  }`}>
                  {title}
                </h1>
              )}
            </Link>
            <ul className="desktop-nav-items flex flex-row ml-auto py-0 my-0">
              {navigation?.navigationItems.map(
                (navigationItem: NavigationItemType) => (
                  <li
                    key={Math.random()}
                    className="my-2 flex flex-row align-items-center">
                    {navigationItem.link.includes('http') ? (
                      <a
                        href={navigationItem.link}
                        target={navigationItem.sameTab ? '' : '_blank'}
                        className={`. ${navigationItem.cssClass}`}
                        rel="noreferrer">
                        {navigationItem.label}
                      </a>
                    ) : (
                      <Link
                        to={navigationItem.link}
                        className={`. ${navigationItem.cssClass}`}>
                        {navigationItem.label}
                      </Link>
                    )}
                  </li>
                ),
              )}
            </ul>
          </div>
        </nav>
      )}
      {/* <div className="spacer" style={{ height: '1rem' }} /> */}
    </>
  );
}
