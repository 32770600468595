import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ArtistContext } from 'context/ArtistContext';
import { AlbumType } from 'types';
import './albums.scss';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import Loader from '../../components/loader/Loader';

const GET_ALBUMS = gql`
  query GET_ALBUMS {
    albums(first: 100, orderBy: releaseDate_DESC) {
      id
      albumUrl
      title
      releaseDate
      albumCover {
        id
        url
      }
    }
  }
`;

function Albums() {
  const artist = useContext(ArtistContext);
  const { loading, data } = useQuery(GET_ALBUMS);
  if (loading) return <Loader height="60vh" />;
  const { albums } = data;
  if (!artist) return <div />;

  const { isSpanish } = artist;

  return (
    <>
      <Nav />
      <section className="container dark-section">
        <div className="relative w-10 mx-auto mt-2 mb-4 md:my-8">
          <div className="gradient-bkg">
            <div className="outline-text-wrapper py-6 px-4 w-full">
              <h1 className="outline-text text-center text-2xl text-center md:text-4xl pb-0 my-0">
                {isSpanish ? 'Musica' : 'Music'}
              </h1>
            </div>
          </div>
        </div>
        <div className="grid justify-content-start align-items-center w-10 mx-auto">
          {albums.map((albumItem: AlbumType) => (
            <Link
              to={`/music/${albumItem.albumUrl}`}
              key={albumItem.albumUrl}
              className="col-10 md:col-4 ml-auto mb-4 no-underline text-center max-w-max mx-auto">
              {!!albumItem.albumCover?.url && (
                <img
                  src={albumItem.albumCover.url}
                  alt={(albumItem.title && albumItem.title) || ''}
                  className="w-full mx-auto"
                />
              )}
              {!!albumItem.title && (
                <p className="font-bold mb-1 pb-0">{albumItem.title}</p>
              )}
            </Link>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Albums;
