import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSpotify,
  faInstagram,
  faFacebook,
  faTiktok,
  faTwitter,
  faYoutube,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import { NavigationItemType } from 'types';
import { ArtistContext } from 'context/ArtistContext';

interface FooterProps {
  logoSecondary?: { url?: string };
  useSecondaryNav?: boolean;
}

export default function Footer(props: FooterProps) {
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;

  const {
    title,
    logo,
    isSpanish,
    facebookLink,
    tikTokLink,
    instagramLink,
    spotifyLink,
    twitterLink,
    youtubeLink,
    soundcloudLink,
    miniBio,
    navigation,
    labelLink,
    labelLogo,
    labelName,
  } = artist;

  const { logoSecondary, useSecondaryNav } = props;

  return (
    <footer className="mx-auto mt-8 mb-0 px-4">
      <section
        className="container grid w-full mx-auto
      my-4 dark-section">
        <div className="col-12">
          <Link
            to="/"
            className="max-w-max mx-auto flex border-b-0 no-underline text-link">
            {!!logo || !!logoSecondary ? (
              <img
                src={logoSecondary?.url || logo?.url}
                alt={(title && title) || ''}
                className="footer-logo mx-auto block"
              />
            ) : (
              <h2 className="uppercase">{title}</h2>
            )}
          </Link>
        </div>
        <div className="col-12 w-full">
          {!!miniBio && (
            <p className="font-sm md:w-3 text-center mx-auto">{miniBio}</p>
          )}
        </div>
        <nav className="col-12 mx-auto w-full my-4 flex-wrap">
          <ul className="flex flex-row mx-auto flex-wrap justify-content-center w-full px-0">
            {!useSecondaryNav &&
              navigation?.navigationItems.map(
                (navigationItem: NavigationItemType) => (
                  <li
                    key={Math.random()}
                    className="my-2 flex flex-column align-items-center justify-content-center">
                    {navigationItem.link.includes('http') ? (
                      <a
                        href={navigationItem.link}
                        target="_blank"
                        className={`mx-2 text-center text-link ${navigationItem.cssClass}`}
                        rel="noreferrer">
                        {navigationItem.label}
                      </a>
                    ) : (
                      <Link
                        to={navigationItem.link}
                        className={`mx-2 text-center text-link ${navigationItem.cssClass}`}>
                        {navigationItem.label}
                      </Link>
                    )}
                  </li>
                ),
              )}
            {!!useSecondaryNav &&
              navigation?.navigationSecpndaryItems.map(
                (navigationSecpndaryItem: NavigationItemType) => (
                  <li
                    key={Math.random()}
                    className="my-2 flex flex-column align-items-center justify-content-center">
                    {navigationSecpndaryItem.link.includes('http') ? (
                      <a
                        href={navigationSecpndaryItem.link}
                        target="_blank"
                        className={`mx-2 text-center text-link ${navigationSecpndaryItem.cssClass}`}
                        rel="noreferrer">
                        {navigationSecpndaryItem.label}
                      </a>
                    ) : (
                      <Link
                        to={navigationSecpndaryItem.link}
                        className={`mx-2 text-center text-link ${navigationSecpndaryItem.cssClass}`}>
                        {navigationSecpndaryItem.label}
                      </Link>
                    )}
                  </li>
                ),
              )}
          </ul>
        </nav>
        <nav className="col-12 flex flex-row footer-social-icons justify-content-center">
          {!!instagramLink && (
            <a
              href={instagramLink}
              target="_blank"
              className="max-w-max mx-2 text-center text-link"
              rel="noreferrer">
              <FontAwesomeIcon
                icon={faInstagram as IconProp}
                className="fa-fw"
              />
            </a>
          )}
          {!!spotifyLink && (
            <a
              href={spotifyLink}
              target="_blank"
              className="max-w-max mx-2 text-center text-link"
              rel="noreferrer">
              <FontAwesomeIcon icon={faSpotify as IconProp} className="fa-fw" />
            </a>
          )}
          {!!facebookLink && (
            <a
              href={facebookLink}
              target="_blank"
              className="max-w-max mx-2 text-center text-link"
              rel="noreferrer">
              <FontAwesomeIcon
                icon={faFacebook as IconProp}
                className="fa-fw"
              />
            </a>
          )}
          {!!twitterLink && (
            <a
              href={twitterLink}
              target="_blank"
              className="max-w-max mx-2 text-center text-link"
              rel="noreferrer">
              <FontAwesomeIcon icon={faTwitter as IconProp} className="fa-fw" />
            </a>
          )}

          {!!youtubeLink && (
            <a
              href={youtubeLink}
              target="_blank"
              className="max-w-max mx-2 text-center text-link"
              rel="noreferrer">
              <FontAwesomeIcon icon={faYoutube as IconProp} className="fa-fw" />
            </a>
          )}
          {!!tikTokLink && (
            <a
              href={tikTokLink}
              target="_blank"
              className="max-w-max mx-2 text-center"
              rel="noreferrer">
              <FontAwesomeIcon icon={faTiktok as IconProp} className="fa-fw" />
            </a>
          )}
          {!!soundcloudLink && (
            <a
              href={soundcloudLink}
              target="_blank"
              className="max-w-max mx-2 text-center"
              rel="noreferrer">
              <FontAwesomeIcon
                icon={faSoundcloud as IconProp}
                className="fa-fw"
              />
            </a>
          )}
        </nav>
        {!!labelLogo && (
          <a
            href={labelLink}
            target="_blank"
            rel="noreferrer"
            className="mx-auto block max-w-max w-full no-underline mt-4 mb-0 p-0">
            <img
              src={labelLogo.url}
              alt={(!!labelName && labelName) || ''}
              className="label-logo m-0 p-0"
              style={{ maxWidth: '100px', objectFit: 'cover' }}
            />
          </a>
        )}
      </section>
      <section className="container mx-auto dark-section mb-8 md:mb-0">
        <p className="col-12 text-center my-auto mx-auto text-xs md:text-base text-color-secondary opacity-50">
          {`© ${new Date().getFullYear()} ${!!title && title} ${
            isSpanish ? 'Todos Derechos Reservados' : 'All Rights Reserved'
          }.`}
        </p>
        {!!title && (
          <p className="col-12 text-center my-auto mx-auto text-xs md:text-base text-color-secondary opacity-50">
            <a
              href={`https://ricardobautista.netlify.app/?${encodeURIComponent(
                title,
              )}%20fan`}
              target="_blank"
              rel="noreferrer"
              className="text-link text-xs opacity-30 uppercase">
              {isSpanish
                ? 'Hecho a mano por Ricardo Bautista'
                : 'Hand crafted by Ricardo Bautista'}
            </a>
          </p>
        )}
      </section>
    </footer>
  );
}
