/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import parse from 'html-react-parser';
import './VideoCategory.scss';
import { VideoCategoryItemType } from 'types';
import { TabView, TabPanel } from 'primereact/tabview';
import YouTubePlaylist from '@codesweetly/react-youtube-playlist';

const GET_HOME = gql`
  query GET_HOME {
    homePages {
      homeVideoCategory {
        categoryName
        categorySlug
        categoryYoutubePlaylistId
        categoryDescription {
          raw
          html
          markdown
          text
        }
        categoryImage {
          id
          url
        }
        categoryCssClassWrapper
        categoryJson
      }
    }
  }
`;

const tabHeaderTemplate = options => (
  <button
    type="button"
    onClick={options.onClick}
    className={`text-2xl md:text-4xl uppercase ${options.className}`}>
    {options.titleElement}
  </button>
);

function VideoCategory() {
  const { loading, data } = useQuery(GET_HOME);

  if (loading) return <div />;
  const home = data.homePages[0];
  const { homeVideoCategory } = home;

  return (
    <div>
      {!!homeVideoCategory && (
        <section className="container my-8">
          <div className="card">
            <TabView>
              {homeVideoCategory.map(
                (homeVideoCategoryItem: VideoCategoryItemType) => (
                  <TabPanel
                    header={homeVideoCategoryItem?.categoryName}
                    headerTemplate={tabHeaderTemplate}
                    headerClassName="flex align-items-center"
                    key={homeVideoCategoryItem.categoryYoutubePlaylistId}>
                    {homeVideoCategoryItem.categoryDescription && (
                      <div className="m-0">
                        {parse(homeVideoCategoryItem.categoryDescription.html)}
                      </div>
                    )}
                    {!!homeVideoCategoryItem.categoryYoutubePlaylistId &&
                      !!process.env.REACT_APP_YOUTUBE_API && (
                        <YouTubePlaylist
                          apiKey={process.env.REACT_APP_YOUTUBE_API}
                          playlistId={
                            homeVideoCategoryItem.categoryYoutubePlaylistId
                          }
                          uniqueName={
                            homeVideoCategoryItem.categoryYoutubePlaylistId
                          }
                        />
                      )}
                  </TabPanel>
                ),
              )}
            </TabView>
          </div>
        </section>
      )}
    </div>
  );
}

export default VideoCategory;
